import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../assets/styles/blog-post.scss';

const Post = ({ data }) => {
  const post = data.datoCmsPost;
  const image = getImage(post.thumbnail);

  const schema = () => {
    return {
      '@context': 'http://schema.org/',
      '@type': 'BlogPosting',
      'headline': post.title,
      'datePublished': '2019-12-05T10:52:00+1100',
      'dateModified': '2021-02-16T10:16:00+1100',
      'author': {
        '@type': 'Organization',
        'name': 'The Croft Practice',
        'url': process.env.GATSBY_SITE_URL,
      },
      'publisher': {
        '@type': 'Organization',
        'name': 'The Croft Practice',
      },
      'mainEntityOfPage': {
        '@type':
          'WebPage',
        '@id': post.thumbnail.url,
      },
      'image': {
        '@type': 'ImageObject',
        'url': post.thumbnail.url,
        'width': 400,
        'height': 300,
      },
    };
  };

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schema())}
        </script>
      </Helmet>
      {post.seo && <SEO title={post.seo.title} description={post.seo.description} />}
      <h1>{post.title}</h1>
      <div className="image-wrapper">
        <GatsbyImage image={image} alt="post thumbnail" />
      </div>
      {// eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      }
      <span className="date">
        Published on
        {' '}
        {post.publishDate}
      </span>
      <Link className="date" to="/blog">Back</Link>

    </Layout>
  );
};

export default Post;

export const query = graphql`
  query Post($slug: String!) {
    datoCmsPost(slug: { eq: $slug }) {
      title
      seo {
        description
        title
      }
      thumbnail {
        gatsbyImageData(placeholder: BLURRED)
        alt
        url
      }
      publishDate(formatString: "D/MM/YYYY")
      content
    }
  }
`;

Post.propTypes = {
  data: PropTypes.shape().isRequired,
};
